import * as React from 'react';
import { Box, Text, Flex, Icon, Button, useHover } from '@churni/styleguide';

import { routes } from '@churni/common';

import { DashboardHeader } from '../DashboardLayout/DashboardHeader';
import { useCurrentUser, useDatabase } from '@churni/fire';
import { useCompany, Setup, Invite } from '../containers';
import { Company } from '@churni/core';
import { navigate } from 'gatsby';
import { SEO } from '../SEO';
import { Panel } from '../NewDashboard/Panel';

export function GettingStarted(): React.ReactElement {
  const db = useDatabase();
  const user = useCurrentUser();
  const { openPopup: openSetupPopup } = Setup.useContainer();
  const { openPopup: openInvitePopup } = Invite.useContainer();
  const company = useCompany();

  const onSkip = async (field: string) => {
    await Company.queryByID(db)(company.id).update({
      onboarding: {
        ...company.onboarding,
        [field]: 'skip'
      }
    });
  };

  const onUnskip = async (field: string) => {
    await Company.queryByID(db)(company.id).update({
      onboarding: {
        ...company.onboarding,
        [field]: 'todo'
      }
    });
  };

  const isEmailProvider = user.providerData[0].providerId === 'password';

  return (
    <>
      <SEO title={'Getting Started'} />
      <DashboardHeader title="Getting Started" sx={{ borderBottom: 0 }} />
      <Panel>
        <Flex pb={2} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            <Text variant="heading1">
              Complete these tasks to start using Churni
            </Text>
          </Box>
        </Flex>
        <Task title={'Create your account'} completed />
        {isEmailProvider && (
          <Task
            title={'Verify your email address'}
            description={
              'We have sent you a verification email. Please verify it to ensure the security of your account.'
            }
            completed={user.emailVerified}
          />
        )}

        <Task
          title={'Personalize your cancellation survey'}
          description={
            'Build your customer exit survey, ask questions relevant to your product and save it.'
          }
          completed={['done', 'skip'].includes(company.onboarding.survey)}
          secondaryCTA={
            company.onboarding.survey !== 'done' &&
            (company.onboarding.survey === 'skip' ? (
              <Button
                className="unskip-survey"
                variant="text"
                size={'small'}
                onClick={() => onUnskip('survey')}
              >
                Undo
              </Button>
            ) : (
              <Button
                className="skip-survey"
                variant="text"
                size={'small'}
                onClick={() => onSkip('survey')}
              >
                Skip
              </Button>
            ))
          }
          cta={
            <Button
              className={'link-form'}
              variant="primaryOutline"
              size={'small'}
              onClick={() => navigate(routes.flow())}
            >
              Personalize
            </Button>
          }
        />
        <Task
          title={'Automate your cancellation process'}
          description={
            'Embed third-party software in your cancellation survey & Automate cancellation actions with Webhooks and Zapier'
          }
          completed={['done', 'skip'].includes(company.onboarding.integrations)}
          secondaryCTA={
            company.onboarding.integrations !== 'done' &&
            (company.onboarding.integrations === 'skip' ? (
              <Button
                className="unskip-integrations"
                variant="text"
                size={'small'}
                onClick={() => onUnskip('integrations')}
              >
                Undo
              </Button>
            ) : (
              <Button
                className="skip-integrations"
                variant="text"
                size={'small'}
                onClick={() => onSkip('integrations')}
              >
                Skip
              </Button>
            ))
          }
          cta={
            <Button
              className={'link-integrations'}
              variant="primaryOutline"
              size={'small'}
              onClick={() => navigate(routes.integrations())}
            >
              Setup
            </Button>
          }
        />
        <Task
          title={'Invite your team'}
          description={
            'Invite and collaborate with as many team members needed.'
          }
          completed={['done', 'skip'].includes(company.onboarding.invite)}
          secondaryCTA={
            company.onboarding.invite !== 'done' &&
            (company.onboarding.invite === 'skip' ? (
              <Button
                className="unskip-invite"
                variant="text"
                size={'small'}
                onClick={() => onUnskip('invite')}
              >
                Undo
              </Button>
            ) : (
              <Button
                className="skip-invite"
                variant="text"
                size={'small'}
                onClick={() => onSkip('invite')}
              >
                Skip
              </Button>
            ))
          }
          cta={
            <Button
              className={'open-invite-popup'}
              variant="primaryOutline"
              size={'small'}
              onClick={openInvitePopup}
            >
              Invite team
            </Button>
          }
        />
        <Task
          title={'Install Churni'}
          description={
            'Redirect the customer to your cancellation flow when he shows an intent to cancel.'
          }
          completed={['done'].includes(company.onboarding.connect)}
          cta={
            <Button
              className={'open-setup-popup'}
              variant="primaryOutline"
              size={'small'}
              onClick={openSetupPopup}
            >
              Connect
            </Button>
          }
        />
      </Panel>
    </>
  );
}

export function Task(props: {
  title: string;
  description?: string;
  completed: boolean;
  cta: React.ReactElement;
  secondaryCTA: React.ReactElement;
}): React.ReactElement {
  const { title, description, completed, cta, secondaryCTA } = props;

  const [ref, isHover] = useHover();

  return (
    <Flex
      ref={ref}
      sx={{ borderTop: 1, borderColor: 'border' }}
      alignItems={'center'}
      flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
      justifyContent={['center', 'center', 'center', 'space-between']}
      p={2}
    >
      <Box color={completed ? 'primary' : 'text'}>
        <Icon.Check />
      </Box>
      <Box ml={2} flex={1} sx={{ minWidth: 300 }}>
        <Box sx={{ textAlign: ['center', 'left'] }}>
          <Text
            variant="subtitle2"
            sx={{
              textDecoration: completed ? 'line-through' : 'inherit'
            }}
          >
            {title}
          </Text>
        </Box>
        {description && (
          <Box sx={{ textAlign: ['center', 'left'] }}>
            <Text variant="body2">{description}</Text>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          minWidth: 240,
          textAlign: ['center', 'center', 'center', 'right'],
          mt: [1, 1, 1, 0]
        }}
      >
        <Box sx={{ display: ['none', 'none', 'none', 'inline-block'] }}>
          {isHover && secondaryCTA}
        </Box>
        <Box
          sx={{
            display: ['inline-block', 'inline-block', 'inline-block', 'none']
          }}
        >
          {secondaryCTA}
        </Box>
        {!completed && cta}
      </Box>
    </Flex>
  );
}
